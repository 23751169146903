(function ($){
  $(document).on("ready", onReady);

  function onReady(e){
    $("a.search-toggle").on("click",onSearchToggleClick);
  }

   function onSearchToggleClick(e){
   $("div.search").removeClass("hidden");
     return false;
   }




})(jQuery)


